var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p-table', {
    attrs: {
      "identifier-field": "idReport",
      "identifier-icon": "icon",
      "height": _vm.height,
      "headers": _vm.getHeaders(),
      "data-table": _vm.format(_vm.dataResult),
      "config-paginnation": !_vm.showDetail ? _vm.paginationData : _vm.paginationDetail,
      "search": _vm.searchTable,
      "is-loading": _vm.isLoading
    },
    on: {
      "page": function page($event) {
        return _vm.checkDetailConversation($event, 'page');
      },
      "ordination": function ordination($event) {
        return _vm.checkDetailConversation($event, 'ordination');
      }
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }