import { mapGetters, mapActions } from 'vuex'
import { ADD_TOAST_MESSAGE } from 'vuex-toast'
import _ from 'lodash'

export default {
  props: ['toogleIsEditing', 'searchString', 'search', 'height'],
  data () {
    return {
      dataResult: [],
      headersList: [
        {
          text: 'Expressão',
          value: 'expression',
          align: 'left',
          width: 'calc(100% - 400px)',
          isSort: true
        },
        {
          text: 'Data',
          value: 'data',
          align: 'center',
          width: '200px',
          isSort: true
        },
        {
          text: 'QTD',
          value: 'qtd',
          align: 'center',
          width: '100px',
          isSort: true
        },
        {
          text: '',
          value: 'actionSelect',
          align: 'center',
          width: '200px'
        }
      ],
      actionSelect: {
        label: 'Selecione',
        value: 'id',
        text: 'name',
        list: 'addIntention',
        setSelected: 'id'
      },
      paginationData: {
        show: false,
        pagesCount: 1,
        totalVisible: 6,
        page: 1,
        ordinationType: 'asc',
        ordinationField: 'createdAt'
      },
      searchTable: {
        field: 'text',
        text: ''
      },
      expressionSelect: {},
      isLoading: false
    }
  },
  computed: {
    ...mapGetters([
      'getterSelectedBot'
    ])
  },
  watch: {
    search (to) {
      this.searchTable.text = to
    }
  },
  created () {
    this.$emit('interactions', 0)
  },
  mounted () {
    this.isLoading = true
    this.getReport()
  },
  methods: {
    ...mapActions([
      'actionGetReportUnknownExpressions'
      // 'actionAddExpression'
    ]),
    ...mapActions({
      addToast: ADD_TOAST_MESSAGE
    }),
    getReport () {
      this.$emit('isLoading', this.isLoading)

      this.actionGetReportUnknownExpressions(this.getterSelectedBot.idBot)
        .then((response) => {
          this.addToast({
            text: 'Relatório de treinamento ativo buscado com sucesso.',
            type: 'success'
          })

          this.isLoading = false
          this.dataResult = response
          this.$emit('interactions', response.length)

          this.paginationData.pagesCount = response.pagesCount
          this.paginationData.show = response.pagesCount > 1

          this.$emit('isLoading', this.isLoading)
        })
        .catch(() => {
          this.addToast({
            text: 'Ocorreu um erro ao buscar os dados do relatório de treinamento ativo, tente novamente mais tarde.',
            type: 'danger'
          })

          this.isLoading = false
          this.$emit('isLoading', this.isLoading)
        })
    },
    addExpression () {
      // this.isLoading = true
      // this.$emit('isLoading', this.isLoading)

      this.itemSelected = {}

      // const newExpression = {
      //   idBot: this.getterSelectedBot.idBot,
      //   exampleText: this.expressionSelect.expressionName,
      //   nameIntent: this.expressionSelect.intentName,
      //   entity: [],
      //   entityLabels: []
      // }

      // this.actionAddExpression(newExpression)
      //   .then(() => {
      //     this.addToast({
      //       text: 'Nova expressão adicionada com sucesso.',
      //       type: 'success'
      //     })

      //     this.expressionSelect = {}
      //     this.dataResult = []
      //     this.getReport()
      //   })
      //   .catch((e) => {
      //     if (e.response.status === 409) {
      //       this.addToast({
      //         text: 'Este registro já está adicionado.',
      //         type: 'warning'
      //       })
      //     } else {
      //       this.addToast({
      //         text: 'Ocorreu um erro ao adicionar a expressão, tente novamente mais tarde.',
      //         type: 'danger'
      //       })
      //     }
      //     this.isLoading = false
      //     this.$emit('isLoading', this.isLoading)
      //   })
    },
    format (data) {
      if (data) {
        const newData = _.cloneDeep(data)
        newData.forEach((item, index) => {
          item.idReport = index
        })
        return newData
      }
      return []
    },
    newExpressionSelect (expression) {
      this.expressionSelect = {
        expressionName: expression.row.expression,
        intentName: expression.row.addIntention.find(item => item.id === expression.item).name
      }
    }
  }
}
