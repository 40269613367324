import { mapGetters } from 'vuex'
import { StandardizeObjects } from '@cedro-technologies/people-vue-ui'
import {
  uniqBy,
  cloneDeep
} from 'lodash'
import {
  SvgLoader,
  CustomDatePicker,
  ExportAndPrinting,
  SelectMenu
} from '@/components'
import { DefaultReports } from '@/helper/defaultReports'
import {
  Conversations,
  Evasion,
  PopularIntentions,
  PopularCategories,
  ExpressionsAssertiveness,
  ResultNPS,
  UnknownExpressions,
  AssertivenessIntentions
} from '@/components/bot-detailed/reports/index.js'
import ReportsApi from '@/api/reports'

export default {
  components: {
    SvgLoader,
    ExportAndPrinting,
    CustomDatePicker,
    Conversations,
    Evasion,
    PopularIntentions,
    PopularCategories,
    ExpressionsAssertiveness,
    ResultNPS,
    UnknownExpressions,
    AssertivenessIntentions,
    SelectMenu
  },
  data () {
    return {
      reports: DefaultReports,
      searchTable: {
        field: 'channel',
        text: ''
      },
      currentPage: 1,
      componentSelect: {},
      showDetail: false,
      isLoading: false,
      height: 700,
      width: 1000,
      filterSelected: {},
      userName: '',
      interactions: 0,
      selectedReport: {},
      search: '',
      isSearch: false,
      currentReportPeriod: { startDate: new Date() },
      styleToPrint: '',
      protocol: '',
      lastProtocols: [],
      conversationProtocols: [],
      conversationClientId: '',
      selectedProtocol: '',
      isLoadingProtocolListSelectMenu: false,
      disableDatePicker: false,
      paginationDetail: {}
    }
  },
  watch: {
    showDetail (to) {
      this.closeSearch()
      if (!to) {
        this.filterSelected = {}
      }
    },
    protocol (value) {
      this.disableDatePicker = value !== ''
    }
  },
  computed: {
    ...mapGetters([
      'getterSelectedBot',
      'getterSelectedCompany'
    ]),
    reportSelected () {
      const type = !this.showDetail ? this.componentSelect.value : ''
      switch (type) {
        case 'conversations':
          return this.$t('reports.conversationReport')
        case 'evasion':
          return this.$t('reports.evasionReport')
        // case 'popularIntentions':
        //   return this.$t('reports.reportPopularIntentions')
        case 'popularCategories':
          return this.$t('reports.popularCategoriesReport')
        case 'expressionsAssertiveness':
          return this.$t('reports.expressionReport')
        case 'resultNPS':
          return this.$t('reports.npsResultsReport')
        case 'unknownExpressions':
          return this.$t('reports.unknownExpressionsReport')
        case 'assertivenessIntentions':
          return this.$t('reports.assertivenessReportIntentions')
        default:
          return this.$t('reports.userReport')
      }
    },
    listOfProtocols () {
      let data = []

      data = this.lastProtocols.concat(this.conversationProtocols).map((i) => ({
        name: i,
        protocol: i
      }))

      data.unshift({ name: this.$t('reports.conversations.allProtocols'), protocol: '' })

      return uniqBy(data, 'protocol')
    }
  },
  created () {
    this.height = document.scrollingElement.offsetHeight - 310
    this.width = document.scrollingElement.offsetWidth
  },
  mounted () {
    window.addEventListener('resize', () => {
      this.checkHeight()
    })
    this.styleToPrint = `
      input,
      .vs__actions,
      .loading,
      .crud-buttons { display: none; }
    `
  },
  methods: {
    closeSearch () {
      this.search = ''
      this.isSearch = false
    },
    cleanCloseSearch () {
      if (!this.search) {
        this.isSearch = false
      }

      if (this.componentSelect.value === 'conversations') {
        if (this.showDetail) {
          this.$refs.report.resetDetailedReportCurrentPage()
          setTimeout(() => {
            this.$refs.report.goToDetailedReport()
          }, 100)
        } else {
          this.$refs.report.resetReportCurrentPage()
          setTimeout(() => {
            this.$refs.report.getReport()
          }, 100)
        }
      }
    },
    togleIsSearch () {
      this.isSearch = !this.isSearch
    },
    getReport (item) {
      this.protocol = this.selectedProtocol = ''
      this.closeSearch()
      this.componentSelect = this.reports[item - 1]
    },
    comeBack () {
      this.selectedReport = {}
      this.showDetail = !this.showDetail
    },
    checkHeight () {
      this.height = window.innerHeight - 310
      this.width = window.innerWidth
    },
    getFilter (id) {
      this.filterSelected = this.componentSelect.filter.find(item => item.id === id)
    },
    translateReport (report) {
      const reportTranslate = cloneDeep(report)
      reportTranslate.forEach(element => {
        element.text = this.$t(element.text)
      })
      return reportTranslate
    },
    translateFilter (filter) {
      const filterTranslate = cloneDeep(filter)
      filterTranslate.forEach(element => {
        element.text = this.$t(element.text)
      })
      return filterTranslate
    },
    clearProtocol () {
      this.protocol = ''

      this.$refs.report.resetReportCurrentPage()

      setTimeout(() => {
        this.$refs.report.getReport()
      }, 100)
    },
    getReportUsingProtocol () {
      this.$refs.report.resetReportCurrentPage()

      setTimeout(() => {
        this.$refs.report.getReport()
      }, 100)
    },
    getConversationInteraction (protocol) {
      this.selectedProtocol = protocol
      this.$refs.report.resetDetailedReportCurrentPage()
      setTimeout(() => {
        this.$refs.report.goToDetailedReport()
      }, 100)
    },
    getConversationsProtocols (protocol) {
      if (protocol !== '') {
        this.isLoadingProtocolListSelectMenu = true

        const params = {
          botId: this.getterSelectedBot.idBot,
          companyId: this.getterSelectedCompany.idCompany,
          clientId: this.conversationClientId,
          startTime: this.currentReportPeriod.startDate,
          endTime: this.currentReportPeriod.endDate,
          protocol,
          page: 1,
          pageSize: 18
        }

        ReportsApi.getRelatoryConversationsProtocols(params)
          .then((response) => {
            const formatedResponse = StandardizeObjects.standardizeObjects(response.data, true)
            this.conversationProtocols = formatedResponse.data.map(i => i.protocol)
            this.isLoadingProtocolListSelectMenu = false
          })
          .catch(() => {
            this.addToast({
              text: this.$t('reports.conversations.toast.errorFetched'),
              type: 'danger'
            })
            this.isLoadingProtocolListSelectMenu = false
          })
      }
    }
  }
}
