import { mapGetters, mapActions } from 'vuex'
import { ADD_TOAST_MESSAGE } from 'vuex-toast'
import moment from 'moment'
import _ from 'lodash'

export default {
  props: ['datePickerData', 'searchString', 'search', 'height', 'setFilter'],
  data () {
    return {
      dataResult: [],
      dataDetail: [],
      paginationData: {
        show: false,
        pagesCount: 1,
        totalVisible: 8,
        currentPage: 1,
        ordinationType: 'asc',
        ordinationField: 'createdAt',
        disabled: true
      },
      searchTable: {
        field: 'userName',
        text: ''
      },
      showDetail: false,
      isLoading: false,
      size: 18,
      lineSelect: {}
    }
  },
  watch: {
    datePickerData () {
      if (!this.showDetail) {
        this.getReport()
      }
    },
    search (to) {
      this.searchTable = {
        field: this.showDetail ? 'message' : 'userName',
        text: to
      }
    },
    setFilter (to) {
      this.getReport(to)
    }
  },
  computed: {
    ...mapGetters([
      'getterSelectedBot',
      '$getterFormatDate'
    ])
  },
  created () {
    this.$emit('interactions', 0)
  },
  mounted () {
    this.getReport()
  },
  methods: {
    ...mapActions([
      'actionGetReportResultNPS'
    ]),
    ...mapActions({
      addToast: ADD_TOAST_MESSAGE
    }),
    getReport () {
      this.isLoading = true
      this.paginationData.disabled = this.isLoading

      this.$emit('isLoading', this.isLoading)

      const params = {
        idBot: this.getterSelectedBot.idBot,
        ...this.datePickerData,
        ...this.paginationData,
        size: this.size
      }

      this.actionGetReportResultNPS(params)
        .then((response) => {
          this.addToast({
            text: this.$t('reports.npsResult.toast.searchedSuccessfully'),
            type: 'success'
          })

          this.isLoading = false
          this.$emit('isLoading', this.isLoading)
          this.dataResult = response
          this.$emit('interactions', response.length)

          this.paginationData.pagesCount = response.pagesCount
          this.paginationData.show = response.pagesCount > 1
          this.paginationData.disabled = this.isLoading

          this.$emit('isLoading', this.isLoading)
        })
        .catch(() => {
          this.addToast({
            text: this.$t('reports.npsResult.toast.errorFetching'),
            type: 'danger'
          })
          this.paginationData.show = false

          this.isLoading = false

          this.$emit('isLoading', this.isLoading)
        })
    },
    format (data) {
      if (data) {
        const newData = _.cloneDeep(data)
        newData.forEach((item, index) => {
          item.idReport = index
          const date = new Date(item.createdAt).toISOString()
          item.createdAt = `${moment(date).format(this.$getterFormatDate)} <strong>${moment(date).format('HH:mm')}</strong>`
          const dateNPS = new Date(item.createdNPS).toISOString()
          item.createdNPS = `${moment(date).format(this.$getterFormatDate)} <strong>${moment(dateNPS).format('HH:mm')}</strong>`
          item.note = `<strong>${item.note}</strong>`
          item.icon = item.channelId === 'peoplechat' ? 'people' : item.channelId
          if (item.message) {
            item.message = item.message === 'endOfConversation' ? this.$t('reports.npsResult.userEvadedConversation') : item.message
          }
        })
        return newData
      }
      return []
    },
    checkDetailConversation (event, call) {
      switch (call) {
        case 'ordination':
          this.paginationData.ordinationType = event.ordinationType
          this.paginationData.ordinationField = event.ordinationField === 'icon' ? 'channelId' : event.ordinationField
          // eslint-disable-next-line no-unused-expressions
          !this.showDetail ? this.getReport() : this.goToDetailedReport()
          break
        case 'page':
          this.paginationData.currentPage = event
          // eslint-disable-next-line no-unused-expressions
          !this.showDetail ? this.getReport() : this.goToDetailedReport()
          break
        default:
          break
      }
    },
    getHeaders () {
      const headers = [
        {
          text: this.$t('reports.npsResult.channel'),
          value: 'icon',
          align: 'center',
          width: '10%',
          isIcon: true,
          externalSort: true
        },
        {
          text: this.$t('reports.npsResult.user'),
          value: 'userName',
          align: 'left',
          width: '20%',
          isSort: true,
          externalSort: true
        },
        {
          text: this.$t('reports.npsResult.dateIteration'),
          value: 'createdAt',
          align: 'center',
          width: '30%',
          isSort: true,
          externalSort: true
        }
      ]
      if (this.setFilter.id === 1) {
        headers.push({
          text: this.$t('reports.npsResult.npsAssessmentDate'),
          value: 'createdNPS',
          align: 'center',
          width: '30%',
          isSort: true,
          externalSort: true
        })
        headers.push({
          text: this.$t('reports.npsResult.note'),
          value: 'note',
          align: 'center',
          width: '30%',
          isSort: true,
          externalSort: true
        })
      }
      return headers
    }
  }
}
