const DefaultReports = [
  {
    id: 1,
    text: 'reports.conversations.text',
    value: 'conversations',
    attributeSearch: 'reports.conversations.attributeSearch',
    legend: 'reports.conversations.legend',
    detail: 'reports.conversations.detail'
  },
  {
    id: 2,
    text: 'reports.evasion.text',
    value: 'evasion',
    attributeSearch: 'reports.evasion.attributeSearch',
    legend: 'reports.evasion.legend',
    detail: 'reports.evasion.detail'
  },
  // {
  //   id: 3,
  //   text: 'reports.popularIntentions.text',
  //   value: 'popularIntentions',
  //   attributeSearch: 'reports.popularIntentions.attributeSearch',
  //   legend: 'reports.popularIntentions.legend'
  // },
  {
    id: 3,
    text: 'reports.intentionsAssertiveness.text',
    value: 'assertivenessIntentions',
    attributeSearch: 'reports.intentionsAssertiveness.attributeSearch',
    legend: 'reports.intentionsAssertiveness.legend'
  },
  {
    id: 4,
    text: 'reports.expressionsAssertiveness.text',
    value: 'expressionsAssertiveness',
    attributeSearch: 'reports.expressionsAssertiveness.attributeSearch',
    legend: 'reports.expressionsAssertiveness.legend'
  }
  // {
  //   id: 6,
  //   text: 'reports.popularCategories.text',
  //   value: 'popularCategories',
  //   attributeSearch: 'reports.popularCategories.attributeSearch',
  //   legend: 'reports.popularCategories.legend'
  //   nameFilter: 'reports.popularCategories.nameFilter',
  //   sizeFilter: 200,
  //   filter: [
  //     {
  //       id: 1,
  //       text: 'reports.popularCategories.filterIntention'
  //     },
  //     {
  //       id: 2,
  //       text: 'reports.popularCategories.filterResources'
  //     }
  //   ]
  // },
  // {
  //   id: 7,
  //   text: 'reports.npsResult.text',
  //   value: 'resultNPS',
  //   attributeSearch: 'reports.npsResult.attributeSearch',
  //   legend: 'reports.npsResult.legend'
  //   nameFilter: 'reports.npsResult.nameFilter',
  //   sizeFilter: 200,
  //   filter: [
  //     {
  //       id: 1,
  //       text: 'reports.npsResult.filterUsers'
  //     },
  //     {
  //       id: 2,
  //       text: 'reports.npsResult.filterNoUsers'
  //     }
  //   ]
  // },
  // {
  //   id: 8,
  //   text: 'Expressões Desconhecidas',
  //   value: 'unknownExpressions',
  //   attributeSearch: 'expressão',
  //   legend: 'Legenda não descrita.'
  // }
]

export { DefaultReports }
