var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p-table', {
    attrs: {
      "height": _vm.height,
      "identifier-field": "idReport",
      "headers": _vm.headersList,
      "data-table": _vm.format(_vm.dataResult),
      "config-paginnation": _vm.paginationData,
      "search": _vm.searchTable,
      "is-loading": _vm.isLoading,
      "action-select": _vm.actionSelect,
      "callback-edit": _vm.addExpression
    },
    on: {
      "actionSelectOption": _vm.newExpressionSelect
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }