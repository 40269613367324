var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "id": "reports"
    }
  }, [_c('v-row', {
    staticClass: "px-10 page-reports mt-0"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "pb-1 mt-0",
    staticStyle: {
      "border-bottom": "1px solid #0000007d"
    }
  }, [_c('v-col', {
    staticClass: "pa-0 animate-col",
    attrs: {
      "cols": "".concat(_vm.isSearch ? '0' : '11')
    }
  }, [_c('h3', {
    class: {
      'left': _vm.isSearch
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reports.reportTitle')) + " ")])]), _c('v-col', {
    class: "pa-0 d-flex align-items align-center justify-content ".concat(_vm.isSearch ? 'justify-space-between' : 'justify-end', " animate-col"),
    attrs: {
      "cols": "".concat(_vm.isSearch ? '12' : '1')
    }
  }, [_c('v-icon', {
    staticClass: "icons mr-1",
    attrs: {
      "color": "#282828",
      "size": "28"
    },
    on: {
      "click": _vm.togleIsSearch
    }
  }, [_vm._v(" search ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.search,
      expression: "search"
    }],
    staticClass: "search-content text-input animate-input-in",
    class: {
      'animate-input-out': !_vm.isSearch
    },
    attrs: {
      "placeholder": _vm.$t('reports.searchReport', [!_vm.showDetail ? _vm.$t(_vm.componentSelect.attributeSearch) : _vm.$t(_vm.componentSelect.detail), _vm.$t(_vm.componentSelect.text)])
    },
    domProps: {
      "value": _vm.search
    },
    on: {
      "blur": _vm.cleanCloseSearch,
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return function ($event) {
          return $event.target.blur();
        }.apply(null, arguments);
      },
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.search = $event.target.value;
      }
    }
  }), _c('v-icon', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip.auto",
      value: {
        content: _vm.$t('reports.tooltip.useButtonLeft'),
        offset: 10
      },
      expression: "{ content: $t('reports.tooltip.useButtonLeft'), offset: 10 }",
      modifiers: {
        "auto": true
      }
    }],
    staticClass: "ml-2 icons",
    attrs: {
      "color": "#282828",
      "size": "28"
    }
  }, [_vm._v(" help ")])], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_vm.showDetail ? _c('v-row', [_c('v-col', {
    staticClass: "px-0 pt-4",
    attrs: {
      "md": "2",
      "lg": "1"
    }
  }, [_c('div', {
    staticClass: "come-back"
  }, [_c('div', {
    staticClass: "d-flex align-items align-center",
    on: {
      "click": _vm.comeBack
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "28",
      "color": _vm.getterSelectedBot.botColor
    }
  }, [_vm._v(" chevron_left ")]), _c('span', {
    style: "color: ".concat(_vm.getterSelectedBot.botColor, ";")
  }, [_vm._v(_vm._s(_vm.$t('reports.back')))])], 1)])]), _c('v-col', {
    staticClass: "pt-4 d-flex align-items align-center text-info",
    attrs: {
      "md": _vm.width > 1264 ? '6' : '3',
      "lg": _vm.componentSelect.value === 'conversations' && _vm.protocol === '' ? '4' : '6'
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('reports.user')) + " "), _c('strong', {
    staticClass: "pl-1 black--text"
  }, [_vm._v(_vm._s(_vm.selectedReport.name || _vm.selectedReport.userName))])])]), _c('v-col', {
    staticClass: "px-0 pt-4 d-flex align-items align-center justify-content justify-end text-info",
    attrs: {
      "md": _vm.width > 1264 ? '4' : '3',
      "lg": _vm.componentSelect.value === 'conversations' && _vm.protocol === '' ? '3' : '5'
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('reports.totalIntentions')) + " "), _c('strong', {
    staticClass: "pl-1 black--text"
  }, [_vm._v(_vm._s(_vm.interactions))])])]), _vm.componentSelect.value === 'conversations' && _vm.protocol === '' ? _c('v-col', {
    staticClass: "px-0 pt-4 d-flex align-items align-center justify-content justify-end text-info protocol",
    attrs: {
      "md": "4",
      "lg": "4"
    }
  }, [_c('span', {
    staticClass: "mr-2 select-period d-flex align-items align-center"
  }, [_vm._v(_vm._s(_vm.$t('reports.conversations.protocol')) + ":")]), _c('selectMenu', {
    staticClass: "conversation-protocol-select-menu",
    attrs: {
      "list": _vm.listOfProtocols,
      "set-selected-item": _vm.selectedProtocol,
      "is-loading-list-select-menu": _vm.isLoadingProtocolListSelectMenu,
      "item-text": "name",
      "item-value": "protocol",
      "title": _vm.$t('reports.conversations.selectProtocol')
    },
    on: {
      "actionItem": function actionItem(protocol) {
        return _vm.getConversationInteraction(protocol);
      },
      "searchStringItem": function searchStringItem(searchString) {
        return _vm.getConversationsProtocols(searchString);
      }
    }
  })], 1) : _vm._e()], 1) : _vm._e(), !_vm.showDetail ? _c('v-row', [_c('v-col', {
    staticClass: "pt-4 pl-0",
    class: {
      'd-flex align-items align-center': _vm.width > (_vm.componentSelect.filter || _vm.componentSelect.value === 'conversations' ? 1358 : 1109)
    },
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "mr-2 select-period d-flex align-items align-center"
  }, [_vm._v(_vm._s(_vm.$t('reports.selectReport')))]), _c('p-select', {
    staticStyle: {
      "width": "200px"
    },
    attrs: {
      "item-text": "text",
      "item-value": "id",
      "items": _vm.translateReport(_vm.reports),
      "height": 28,
      "set-selected": _vm.componentSelect.id || 1,
      "disabled": _vm.isLoading
    },
    on: {
      "selected": _vm.getReport
    }
  })], 1), !_vm.componentSelect.isNotDate ? _c('v-col', {
    staticClass: "pl-0 pt-4",
    class: {
      'd-flex align-items align-center': _vm.width > (_vm.componentSelect.filter || _vm.componentSelect.value === 'conversations' ? 1358 : 1109)
    },
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "mr-2 select-period d-flex align-items align-center"
  }, [_vm._v(_vm._s(_vm.$t('reports.selectPeriod')))]), _c('customDatePicker', {
    attrs: {
      "is-loading": _vm.isLoading || _vm.disableDatePicker,
      "date-to-set": _vm.currentReportPeriod
    },
    on: {
      "callback": function callback(date) {
        return _vm.currentReportPeriod = date;
      }
    }
  })], 1) : _vm._e(), _vm.componentSelect.value === 'conversations' ? _c('v-col', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip.auto",
      value: {
        content: _vm.$t('reports.conversations.protocolTooltip'),
        offset: -5
      },
      expression: "{ content: $t('reports.conversations.protocolTooltip'), offset: -5 }",
      modifiers: {
        "auto": true
      }
    }],
    staticClass: "pl-0 pt-4 protocol",
    class: {
      'd-flex align-items align-center': _vm.width > (_vm.componentSelect.filter || _vm.componentSelect.value === 'conversations' ? 1358 : 1109)
    },
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "mr-2 select-period d-flex align-items align-center"
  }, [_vm._v(_vm._s(_vm.$t('reports.conversations.protocol')) + ":")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.protocol,
      expression: "protocol"
    }],
    staticClass: "generic-input",
    attrs: {
      "placeholder": _vm.$t('reports.conversations.insertProtocol')
    },
    domProps: {
      "value": _vm.protocol
    },
    on: {
      "blur": _vm.getReportUsingProtocol,
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return function ($event) {
          return $event.target.blur();
        }.apply(null, arguments);
      },
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.protocol = $event.target.value;
      }
    }
  }), _vm.protocol !== '' ? _c('button', {
    staticClass: "clear",
    on: {
      "click": _vm.clearProtocol
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "#282828",
      "size": "17"
    }
  }, [_vm._v(" close ")])], 1) : _vm._e()]) : _vm._e(), _vm.componentSelect.filter ? _c('v-col', {
    staticClass: "pt-4 pr-0",
    class: {
      'd-flex align-items align-center': _vm.width > (_vm.componentSelect.filter ? 1436 : 1109)
    },
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "mr-2 select-period d-flex align-items align-center"
  }, [_vm._v(_vm._s(_vm.componentSelect.nameFilter) + ":")]), _c('p-select', {
    style: "width: ".concat(_vm.componentSelect.sizeFilter, "px;"),
    attrs: {
      "item-text": "text",
      "item-value": "id",
      "items": _vm.translateFilter(_vm.componentSelect.filter),
      "height": 28,
      "set-selected": 1,
      "disabled": _vm.isLoading
    },
    on: {
      "selected": _vm.getFilter
    }
  })], 1) : _vm._e()], 1) : _vm._e()], 1), _c('v-col', {
    staticClass: "d-flex align-items align-center justify-content justify-end",
    attrs: {
      "cols": "2"
    }
  }, [_c('exportAndPrinting', {
    class: {
      'disabled': !_vm.interactions || _vm.isLoading
    },
    attrs: {
      "search": _vm.search,
      "title": _vm.reportSelected,
      "date-search": _vm.currentReportPeriod,
      "style-to-print": _vm.styleToPrint,
      "user-name": _vm.selectedReport ? _vm.selectedReport.userName : '-',
      "user-nickname": _vm.selectedReport ? _vm.selectedReport.userNickname : '',
      "component-select": _vm.showDetail ? _vm.componentSelect.value === 'conversations' ? 'interactions' : 'detailedReport' : _vm.componentSelect.value,
      "selected-report": _vm.selectedReport,
      "protocol": _vm.protocol || _vm.selectedProtocol,
      "pagination": _vm.paginationDetail,
      "element-to-query-selector-csv": ".v-data-table__wrapper",
      "element-to-query-selector-pdf": ".v-data-table__wrapper",
      "element-to-query-selector-print": ".v-data-table__wrapper"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "px-1 ma-0 legend rounded",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t(_vm.componentSelect.legend))
    }
  })])], 1), _c('v-row', [_c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "12"
    }
  }, [_c(_vm.componentSelect.value, {
    ref: "report",
    tag: "component",
    attrs: {
      "search-string": _vm.search,
      "date-picker-data": _vm.currentReportPeriod,
      "come-back": _vm.showDetail,
      "search": _vm.search,
      "height": _vm.height,
      "set-filter": _vm.filterSelected,
      "protocol": _vm.protocol,
      "conversation-protocol": _vm.selectedProtocol
    },
    on: {
      "showDetail": function showDetail($event) {
        _vm.showDetail = $event;
      },
      "isLoading": function isLoading($event) {
        _vm.isLoading = $event;
      },
      "interactions": function interactions($event) {
        _vm.interactions = $event;
      },
      "selectedReport": function selectedReport($event) {
        _vm.selectedReport = $event;
      },
      "setLastProtocols": function setLastProtocols($event) {
        _vm.lastProtocols = $event;
      },
      "setConversationClientId": function setConversationClientId($event) {
        _vm.conversationClientId = $event;
      },
      "setPaginationReport": function setPaginationReport($event) {
        _vm.paginationDetail = $event;
      }
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }