import { mapGetters, mapActions } from 'vuex'
import { ADD_TOAST_MESSAGE } from 'vuex-toast'
import { cloneDeep } from 'lodash'
import ReportsApi from '@/api/reports'

export default {
  props: ['datePickerData', 'searchString', 'search', 'height'],
  data () {
    return {
      dataResult: [],
      headersList: [
        {
          text: this.$t('reports.expressionsAssertiveness.expression'),
          value: 'expression',
          align: 'left',
          width: '35%',
          isSort: true,
          externalSort: true
        },
        {
          text: this.$t('reports.expressionsAssertiveness.numberTimesFired'),
          value: 'count',
          align: 'center',
          width: '15%',
          isSort: true,
          externalSort: true
        },
        {
          text: this.$t('reports.expressionsAssertiveness.percentageAssertiveness'),
          value: 'score',
          align: 'center',
          width: '20%',
          isSort: true,
          externalSort: true
        },
        {
          text: this.$t('reports.expressionsAssertiveness.intention'),
          value: 'intent',
          align: 'left',
          width: '30%',
          isSort: true,
          externalSort: true
        }
      ],
      paginationData: {
        show: false,
        pagesCount: 1,
        totalVisible: 6,
        currentPage: 1,
        ordinationType: 'asc',
        ordinationField: 'expression'
      },
      searchTable: {
        field: 'expression',
        text: ''
      },
      isLoading: false
    }
  },
  watch: {
    datePickerData () {
      this.getReport()
    },
    search (to) {
      this.searchTable.text = to
    }
  },
  computed: {
    ...mapGetters([
      'getterSelectedBot'
    ])
  },
  created () {
    this.$emit('interactions', 0)
  },
  mounted () {
    this.getReport()
  },
  methods: {
    ...mapActions({
      addToast: ADD_TOAST_MESSAGE
    }),
    getReport () {
      this.isLoading = true
      this.$emit('isLoading', this.isLoading)

      const params = {
        botId: this.getterSelectedBot.idBot,
        ...this.datePickerData,
        page: this.paginationData.currentPage,
        pageCount: 20,
        ordinationType: this.paginationData.ordinationType,
        ordinationField: this.paginationData.ordinationField
      }

      ReportsApi.getReportExpressionsAssertiveness(params.botId, false, params.startDate, params.endDate, params.page, params.pageCount, params.ordinationType, params.ordinationField)
        .then((response) => {
          this.isLoading = false
          this.dataResult = response.data.data
          this.$emit('interactions', response.data.data.length)

          this.paginationData.pagesCount = response.data.amount_pages
          this.paginationData.show = response.data.amount_pages > 1

          this.$emit('isLoading', this.isLoading)
        })
        .catch(() => {
          this.dataResult = []
          this.addToast({
            text: this.$t('reports.expressionsAssertiveness.toast.errorFetching'),
            type: 'danger'
          })

          this.isLoading = false
          this.$emit('isLoading', this.isLoading)
        })
    },
    format (data) {
      if (data) {
        const newData = cloneDeep(data)
        newData.forEach((item, index) => {
          item.idReport = index
          item.intent = item.intent === 'None' ? this.$t('reports.expressionsAssertiveness.unknown') : item.intent
          item.score = `${item.score !== -1 ? Math.round(item.score * 100) : 0}%`
        })
        return newData
      }
      return []
    },
    checkDetailConversation (event, call) {
      switch (call) {
        case 'ordination':
          this.paginationData.ordinationType = event.ordinationType
          this.paginationData.ordinationField = event.ordinationField === 'intent' ? 'intent_id' : event.ordinationField
          this.paginationData.currentPage = 1
          this.getReport()
          break
        case 'page':
          this.paginationData.currentPage = event
          this.getReport()
          break
        default:
          break
      }
    }
  }
}
