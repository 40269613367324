import { mapGetters, mapActions } from 'vuex'
import { ADD_TOAST_MESSAGE } from 'vuex-toast'
import _ from 'lodash'

export default {
  props: ['datePickerData', 'searchString', 'search', 'height', 'setFilter'],
  data () {
    return {
      dataResult: [],
      headersList: [
        {
          text: this.$t('reports.popularCategories.popularExpressions'),
          value: 'expression',
          align: 'left',
          width: '50%',
          isSort: true
        },
        {
          text: this.$t('reports.popularCategories.quantityIdentified'),
          value: 'count',
          align: 'center',
          width: '30%',
          isSort: true
        },
        {
          text: this.$t('reports.popularCategories.intention'),
          value: 'intent',
          align: 'left',
          width: '30%',
          isSort: true
        }
      ],
      paginationData: {
        show: false,
        pagesCount: 1,
        totalVisible: 6,
        page: 1,
        ordinationType: 'asc',
        ordinationField: 'createdAt'
      },
      searchTable: {
        field: 'expression',
        text: ''
      },
      isLoading: false
    }
  },
  watch: {
    datePickerData () {
      this.getReport()
    },
    search (to) {
      this.searchTable.text = to
    },
    setFilter: {
      deep: true,
      handler () {
      }
    }
  },
  computed: {
    ...mapGetters([
      'getterSelectedBot'
    ])
  },
  created () {
    this.$emit('interactions', 0)
  },
  mounted () {
    this.getReport()
  },
  methods: {
    ...mapActions([
      'actionGetReportPopularCategories'
    ]),
    ...mapActions({
      addToast: ADD_TOAST_MESSAGE
    }),
    getReport () {
      this.isLoading = true
      this.$emit('isLoading', this.isLoading)

      this.actionGetReportPopularCategories(this.getterSelectedBot.idBot, this.datePickerData.startDate, this.datePickerData.endDate)
        .then((response) => {
          this.addToast({
            text: this.$t('reports.popularCategories.toast.searchedSuccessfully'),
            type: 'success'
          })

          this.isLoading = false
          this.dataResult = response
          this.$emit('interactions', response.length)

          this.paginationData.pagesCount = response.pagesCount
          this.paginationData.show = response.pagesCount > 1

          this.$emit('isLoading', this.isLoading)
        })
        .catch(() => {
          this.addToast({
            text: this.$t('reports.popularCategories.toast.errorFetching'),
            type: 'danger'
          })

          this.isLoading = false
          this.$emit('isLoading', this.isLoading)
        })
    },
    format (data) {
      if (data) {
        const newData = _.cloneDeep(data)
        newData.forEach((item, index) => {
          item.idReport = index
        })
        return newData
      }
      return []
    }
  }
}
